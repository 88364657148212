<template>
  <div class="url">
    <h3>Incorporar Vídeo</h3>
    <div class="balao" ref="balaoUrl">
      <button class="abrir" @click="abrirIncorporar()" :class="{ ativo: state.linkUrl }">
        <Svgs nome="play" />
        <p>Configurar</p>
      </button>
      <!-- Link -->
      <div class="link" :class="{ mostrar: state.linkUrl }">
        <div class="titulo">
          <p>Incorporar Vídeo</p>
          <button class="fechar" @click="limpar()">
            <Svgs nome="x" />
          </button>
        </div>
        <div class="conteudo">
          <textarea rows="3" spellcheck="false" autocomplete="false" v-model="conteudoTextarea" @input="filtrarInput" placeholder="Cole aqui..." @keydown.enter="salvarUrl" v-redimensionar-textarea></textarea>
          <div class="previa">
            <iframe :src="codigoIframe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen></iframe>
          </div>
          <div class="capa">
            <button class="subir" @click="carregarImagem()" :disabled="state.uploadImagem.desabilitarBotao">
              <Svgs nome="arquivo" />
              <p>Subir</p>
              <p>Capa</p>
              <span></span>
              <UploadImagem :dados="state.uploadImagem" ref="uploadImagem" @atualizarDadosImagem="atualizarDadosImagem($event)" />
            </button>
            <div class="imagem" :style="`background-image: url('${state.imagemCapa || ''}')`"></div>
          </div>
          <button @click="salvarUrl">Salvar vídeo</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, computed } from 'vue'
import { useStoreAjustes } from '@stores'
import { helperClicouFora } from '@helpers'
import Svgs from '@svgs'
import UploadImagem from '@components/global/upload/image.vue'
const props = defineProps(['selecao'])

const storeAjustes = useStoreAjustes()
const balaoUrl = ref(null)
const conteudoTextarea = ref('')
const codigoIframe = ref('')
const uploadImagem = ref(null)

const state = reactive({
  linkUrl: false,
  urlVideo: '',
  imagemCapa: '',
  uploadImagem: {
    tamanhoMaximo: 1920,
    urlImagem: '',
    desabilitarBotao: false
  }
})

function salvarUrl() {
  elementoSelecionado.value.url = state.urlVideo
  elementoSelecionado.value.image = state.imagemCapa
  limpar()
}

function atualizarDadosImagem(dados) {
  if (typeof dados.desabilitarBotao === 'boolean') state.uploadImagem.desabilitarBotao = dados.desabilitarBotao
  if (typeof dados.urlImagem === 'string') {
    state.uploadImagem.urlImagem = dados.urlImagem
    state.imagemCapa = state.uploadImagem.urlImagem
  }
}

function carregarImagem() {
  uploadImagem?.value?.enviarImagem()
}

function limpar() {
  state.urlVideo = ''
  state.imagemCapa = ''
  state.uploadImagem.urlImagem = ''
  conteudoTextarea.value = ''
  codigoIframe.value = ''
  state.linkUrl = false
}

function abrirIncorporar() {
  carregarUrl()
  state.linkUrl = !state.linkUrl
}

function carregarUrl() {
  state.imagemCapa = elementoSelecionado.value.image
  conteudoTextarea.value = elementoSelecionado.value.url
  codigoIframe.value = elementoSelecionado.value.url
}

const filtrarInput = async () => {
  const pegarIframe = conteudoTextarea.value.match(/<iframe.*?src="(.*?)"/)
  const pegarUrl = conteudoTextarea.value.match(/https?:\/\/[^\s]+/)

  if (pegarIframe) {
    codigoIframe.value = pegarIframe[1]
    state.urlVideo = codigoIframe.value
  } else if (pegarUrl) {
    codigoIframe.value = pegarUrl[0]
    state.urlVideo = codigoIframe.value
  } else {
    codigoIframe.value = ''
    state.urlVideo = codigoIframe.value
  }

  if (state.urlVideo.includes('youtube.com') || state.urlVideo.includes('youtu.be')) {
    const videoIdMatch = state.urlVideo.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/)
    if (videoIdMatch) {
      const videoId = videoIdMatch[1]
      state.imagemCapa = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
      state.urlVideo = `https://www.youtube.com/embed/${videoId}`
      codigoIframe.value = `https://www.youtube.com/embed/${videoId}`
    }
  }

  if (state.urlVideo.includes('pandavideo.com.br')) {
    const videoIdMatch = state.urlVideo.match(/\/v=([a-z0-9-]+)/i) || state.urlVideo.match(/\/embed\/\?v=([a-z0-9-]+)/i)
    if (videoIdMatch) {
      const videoId = videoIdMatch[1]
      const servidorId = 'b-vz-cce5a623-141' // Supondo que este ID é constante
      state.imagemCapa = `https://${servidorId}.tv.pandavideo.com.br/${videoId}/thumbnail.jpg`
    }
  }

  if (state.urlVideo.includes('vimeo.com')) {
    const videoIdMatch = state.urlVideo.match(/video\/(\d+)/)
    if (videoIdMatch) {
      const videoId = videoIdMatch[1]
      try {
        const response = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`)
        const data = await response.json()
        state.imagemCapa = data[0].thumbnail_large
      } catch (error) {
        console.error('Erro ao buscar os detalhes do vídeo do Vimeo:', error)
      }
    }
  }
}

helperClicouFora.clicouFora([{ alvo: balaoUrl, retornoChamada: () => limpar() }])

const elementoSelecionado = computed(() => {
  const dispositivo = props.selecao?.dispositivo
  const indice = props.selecao?.indiceSecao
  const elemento = props.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
})
</script>

<style scoped>
.url {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
}

h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  width: 100%;
}

.balao {
  width: 100%;
}

button.abrir {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 10px;
  margin: 10px 0 0 0;
  background-color: var(--cor-cinza-3);
  width: 100%;
  transition: all 0.3s;
}

button.abrir.ativo {
  background-color: var(--cor-cinza-4);
}

button.abrir.ativo p {
  color: var(--cor-branco);
}

button.abrir:hover p {
  color: var(--cor-branco);
}

button.abrir svg {
  width: 15px;
  max-width: 15px;
  max-height: 15px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

button.abrir p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
}

.link.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 250px;
  position: fixed;
  top: 100px;
  right: 270px;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  overflow-y: scroll;
  z-index: 4;
  transition: all 0.3s;
}

.link .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 20px 0;
  width: 100%;
}

.link .titulo p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.link button.fechar {
  background-color: transparent;
}

.link button.fechar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

.link button.fechar:hover svg {
  fill: var(--cor-vermelho);
}

.link .conteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.link .conteudo textarea {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  line-height: 1.5;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  min-height: 100px;
  resize: none;
}

.link .conteudo .previa {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 140px;
  min-height: 140px;
  margin: 10px 0 0 0;
  border-radius: 10px;
  position: relative;
}

.link .conteudo .previa iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  background-color: var(--cor-preto);
}

.link .conteudo button {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  padding: 15px;
  border-radius: 10px;
  background-color: var(--cor-azul);
  width: 100%;
  margin: 10px 0 0 0;
  transition: all 0.3s;
}

.link .conteudo button:hover {
  background-color: var(--cor-azul-escuro);
}

.link .conteudo .capa {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0 0 0;
}

.link .conteudo .capa button.subir {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-3);
  padding: 20px 0;
  width: calc(40% - 5px);
  border-radius: 10px;
  margin: 0;
  position: relative;
  transition: all 0.3s;
}

.link .conteudo .capa button.subir:hover {
  background-color: var(--cor-cinza-4);
}

.link .conteudo .capa button.subir span {
  width: 30px;
  height: 30px;
  border: 3px solid var(--cor-branco);
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: girando 1s linear infinite;
  position: absolute;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.link .conteudo .capa button.subir:disabled span {
  opacity: 1;
  visibility: visible;
}

.link .conteudo .capa button.subir:disabled svg {
  fill: var(--cor-cinza-3);
}

.link .conteudo .capa button.subir:disabled p {
  color: var(--cor-cinza-3);
}

.link .conteudo .capa button.subir svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-branco);
  margin: 0 0 10px 0;
  transition: all 0.3s;
}

.link .conteudo .capa button.subir p {
  font-family: var(--regular);
  color: var(--cor-branco);
  margin: 5px 0 0 0;
  transition: all 0.3s;
}

.link .conteudo .capa .imagem {
  width: calc(60% - 5px);
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .link {
    top: 120px;
    right: 20px;
    width: 100%;
    max-width: 250px;
    height: 42dvh;
    max-height: 42dvh;
    min-height: 42dvh;
  }
}
</style>
