<template>
  <section class="lista">
    <div class="itens">
      <!-- Template -->
      <div class="template" v-for="(template, index) in state.templates" :key="index">
        <div class="autor">
          <div class="foto" :style="`background-image: url('${template.iconeAutor}');`"></div>
          <p>{{ template.autor }}</p>
          <div class="tag" :class="formatarTipo(template.tipo)">
            <h3>{{ template.tipo }}</h3>
          </div>
        </div>
        <div class="descricao">
          <p>{{ template.descricao }}</p>
        </div>
        <div class="capa">
          <div class="tamanho">
            <div class="imagem" :style="`background-image: url('${template.imagemCapa}');`"></div>
          </div>
        </div>
        <div class="botoes">
          <button class="botao usar" @click="abrirModalCriarComTemplate(index)">
            <Svgs nome="mais" />
            <p>Criar site</p>
          </button>
          <a class="botao previa" target="_blank" :href="`https://unic.page/${template.hotlink}`">
            <Svgs nome="visualizar" />
            <p>Ver prévia</p>
          </a>
        </div>
        <h5 v-if="template.novo" class="novo">NOVO</h5>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { useStoreTemplates, useStoreModal } from '@stores'
import Svgs from '@svgs'

const storeTemplates = useStoreTemplates()
const storeModal = useStoreModal()

const state = reactive({
  templates: [
    {
      hotlink: 'template-estetica-automotiva',
      autor: 'UnicPages',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para Estéticas Automotivas e Lava-jatos',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-unic-estetica-automotiva.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-unic.png',
      novo: false
    },
    {
      hotlink: 'template-imobiliaria',
      autor: 'Edu Sites',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para Imobiliárias e Corretores',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-unic-imobiliaria.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-edu.png',
      novo: false
    },
    {
      hotlink: 'template-clinica-medica',
      autor: 'UnicPages',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para Clínicas e Médicos',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-unic-clinica-medica.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-unic.png',
      novo: false
    },
    {
      hotlink: 'template-bio-insta',
      autor: 'Eu Sou o Mac',
      tipo: 'Link de Bio',
      descricao: 'Modelo para link de bio',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-power-bio-instagram.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-mac.png',
      novo: false
    },
    {
      hotlink: 'template-power-samia',
      autor: 'Produtora Power',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para produto feminino',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-power-samia.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-power.png',
      novo: false
    },
    {
      hotlink: 'template-exp-trafego-pagox',
      autor: 'Edu Sites',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para gestor de tráfego',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-template-trafego-pagox.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-edu.png',
      novo: false
    },
    {
      hotlink: 'template-power-diogo',
      autor: 'Diogo Viana',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para curso ou mentoria',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-power-diogo.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-diogo.png',
      novo: false
    },
    {
      hotlink: 'template-power-programa-ex',
      autor: 'Saulo Barbosa',
      tipo: 'Página de Captura',
      descricao: 'Modelo para página de captura',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-power-programa-ex.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-saulo.png',
      novo: false
    },
    {
      hotlink: 'template-power-coprodutor7d',
      autor: 'Eu Sou o Mac',
      tipo: 'Página de Captura',
      descricao: 'Modelo para captura de evento',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-power-coprodutor7d.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-mac.png',
      novo: false
    },
    {
      hotlink: 'template-power-programa-elite',
      autor: 'Eu Sou o Mac',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para curso ou treinamento online',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-programa-elite.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-mac.png',
      novo: false
    },
    {
      hotlink: 'template-power-ingresso-rj',
      autor: 'Produtora Power',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para eventos presenciais',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-template-ingresso-rj.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-power.png',
      novo: false
    },
    {
      hotlink: 'template-exp-marketing',
      autor: 'Edu Sites',
      tipo: 'Página de Vendas',
      descricao: 'Modelo para portfólio ou vitrine',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-template-xp-marketing.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-edu.png',
      novo: false
    },
    {
      hotlink: 'template-power-macletter',
      autor: 'Eu Sou o Mac',
      tipo: 'Página de Captura',
      descricao: 'Modelo para captura simples',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-power-macletter.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-mac.png',
      novo: false
    },
    {
      hotlink: 'template-exp-turnkey',
      autor: 'Edu Sites',
      tipo: 'Página de Captura',
      descricao: 'Modelo para página de captura',
      imagemCapa: 'https://arquivos.unicpages.com/imagens/app/templates/capa-exp-turnkey.png',
      iconeAutor: 'https://arquivos.unicpages.com/imagens/app/templates/autores/autor-edu.png',
      novo: false
    }
  ]
})

function formatarTipo(texto) {
  texto = texto
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
  texto = texto.replace(/\s+/g, '')

  return texto
}

function abrirModalCriarComTemplate(index) {
  storeTemplates.hotlinkTemplate = state.templates[index].hotlink
  storeTemplates.imagemCapa = state.templates[index].imagemCapa
  storeTemplates.iconeAutor = state.templates[index].iconeAutor
  storeTemplates.autor = state.templates[index].autor
  storeTemplates.tipo = state.templates[index].tipo
  storeModal.abrirModal('criarComTemplate', 'template', 'Criar com template')
}
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0 0 320px;
}

.opcoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 20px);
  background-color: var(--cor-cinza-2);
  padding: 20px;
  border-radius: 10px;
  margin: 0 0 20px 0;
}

.opcoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-2);
  padding: 20px;
  width: 25%;
  border-radius: 10px;
  transition: all 0.3s;
}

.opcoes button:hover p {
  color: var(--cor-branco);
}

.opcoes button.ativo {
  background-color: var(--cor-cinza-3);
}

.opcoes button.ativo p {
  color: var(--cor-branco);
}

.opcoes button.ativo {
  background-color: var(--cor-cinza-3);
}

.opcoes button p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
}

.opcoes button h5 {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  background-color: var(--cor-cinza-4);
  padding: 2px 3px;
  border-radius: 3px;
  margin: 0 5px 0 0;
  transition: all 0.3s;
}

.opcoes button.vendas h5 {
  color: var(--cor-verde);
  background-color: #22bc5844;
}

.opcoes button.captura h5 {
  color: var(--cor-vermelho);
  background-color: #f53b3a44;
}

.opcoes button.bio h5 {
  color: var(--cor-rosa);
  background-color: #ea42f844;
}

.opcoes button.bio svg {
  fill: var(--cor-rosa);
}

.itens {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-height: 75dvh;
}

.template {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(33.33% - 20px);
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  position: relative;
  margin: 0 20px 20px 0;
  transition: all 0.3s;
  animation: fadeIn 0.3s linear;
}

h5.novo {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 7px 20px;
  border-radius: 30px;
  background-color: var(--cor-azul);
  box-shadow: 0 10px 30px 0 #1c94ef95;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.template .capa {
  width: 100%;
}

.template .capa .tamanho {
  position: relative;
  padding-bottom: 56.52%;
  width: 100%;
}

.template .capa .tamanho .imagem {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--cor-cinza-2);
  background-size: cover;
  background-position: center;
}

.template .descricao {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;
  border-top: 1px solid var(--cor-cinza-3);
}

.template .descricao p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}

.template .autor {
  display: flex;
  align-items: center;
  margin-right: auto;
  width: 100%;
  background-color: var(--cor-cinza-2);
  height: 60px;
  padding: 0 20px;
  border-radius: 10px 10px 0 0;
}

.template .autor .foto {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: var(--cor-cinza-4);
  background-size: cover;
  background-position: center;
  margin: 0 5px 0 0;
}

.template .autor p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.template .autor .tag {
  padding: 5px 10px;
  border-radius: 50px;
  background-color: var(--cor-cinza-2);
  margin-left: auto;
}

.template .autor .tag.paginadevendas {
  border: 0.5px solid var(--cor-verde);
}

.template .autor .tag.paginadevendas h3 {
  color: var(--cor-verde);
}

.template .autor .tag.linkdebio {
  border: 0.5px solid var(--cor-rosa);
}

.template .autor .tag.linkdebio h3 {
  color: var(--cor-rosa);
}

.template .autor .tag.paginadecaptura {
  border: 0.5px solid var(--cor-vermelho);
}

.template .autor .tag.paginadecaptura h3 {
  color: var(--cor-vermelho);
}

.template .autor .tag h3 {
  font-family: var(--bold);
  font-size: var(--f0);
  color: var(--cor-branco);
}

.template .botoes {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.template .botoes .botao {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-2);
  height: 60px;
  margin: 0 15px;
  transition: all 0.3s;
}

.template .botoes .botao:hover p {
  color: var(--cor-azul);
}

.template .botoes .botao:hover svg {
  fill: var(--cor-azul);
}

.template .botoes .botao p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  transition: all 0.3s;
}

.template .botoes .botao.previa:hover {
  opacity: 0.6;
}

.template .botoes .botao.previa:hover p {
  color: var(--cor-branco);
}

.template .botoes .botao.previa:hover svg {
  fill: var(--cor-branco);
}

.template .botoes .botao svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.lista {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 20px 20px 0 20px;
    min-height: 75dvh;
  }

  .opcoes {
    flex-wrap: wrap;
    width: 100%;
    padding: 10px;
  }

  .opcoes button {
    padding: 15px;
    width: 50%;
  }

  .opcoes button p {
    font-size: var(--f1);
  }

  .opcoes button h5 {
    font-size: var(--f0);
  }

  .itens {
    padding: 0;
  }

  .template {
    width: 100%;
    margin: 0 0 20px 0;
  }

  .template .capa {
    width: 100%;
  }

  .template .autor .foto {
    width: 16px;
    height: 16px;
  }
}
</style>
