<template>
  <Modal nome="cloakup">
    <label class="link" :class="{ maior: state.links.length === 0 }">
      Links da sua conta Cloakup
      <button @click="adicionarLink">
        <Svgs nome="link" />
        Adicionar link
      </button>
    </label>
    <div v-for="(link, index) in state.links" :key="index" class="input">
      <input type="text" v-model="link.url" autocomplete="false" placeholder="https://link.com" spellcheck="false" />
      <button class="excluir" @click="removerLink(index)">
        <Svgs nome="excluir" />
      </button>
    </div>

    <BotaoSalvar nome="Salvar Cloakup" @click="salvarCloakup" />
  </Modal>
</template>

<script setup>
import { reactive, inject, onMounted } from 'vue'
import { useStorePages, useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storePages = useStorePages()
const storeModal = useStoreModal()

const state = reactive({
  links: []
})

const adicionarLink = () => {
  state.links.push({ url: '' })
}

const removerLink = (index) => {
  state.links.splice(index, 1)
}

async function salvarCloakup() {
  try {
    const linksParaSalvar = state.links.map((link) => link.url)

    const payload = {
      idPage: storePages.page._id,
      integracoes: {
        cloakUp: {
          urls: linksParaSalvar
        }
      }
    }

    console.log('Payload para salvar:', payload)

    await storePages.alterarDados(payload)

    setTimeout(() => {
      storeModal.fecharModal('cloakup')
      window.location.reload()
    }, 800)
  } catch (error) {
    console.error('Erro ao salvar Cloakup:', error)
  }
}

function carregarDados() {
  state.links = (storePages.page?.integracoes?.cloakUp?.urls || []).map((url) => ({ url }))
}

function limparCampos() {
  state.links = [{ url: '' }]
}

onMounted(() => {
  emitter.on('carregarCampos', carregarDados)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 10px 0;
  position: relative;
}

label button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-laranja);
  padding: 5px 10px;
  border-radius: 5px;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco-fixo);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

label button:hover {
  background-color: var(--cor-laranja-escuro);
}

label button svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-branco-fixo);
  margin: 0 5px 0 0;
}

button.salvar {
  margin: 10px 0 0 0;
}

.input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin: 0 0 10px 0;
}

.input input {
  padding: 15px;
}

.input button.excluir {
  position: absolute;
  right: 5px;
  background-color: var(--cor-cinza-4);
  width: 30px;
  height: 30px;
  border-radius: 5px;
  transition: all 0.3s;
}

.input button.excluir:hover {
  background-color: var(--cor-vermelho);
}

.input button.excluir:hover svg {
  fill: var(--cor-branco-fixo);
}

.input button.excluir svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

a {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s;
}

a:hover {
  opacity: 0.6;
}

a svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
}
</style>
