<template>
  <Modal nome="contratar">
    <!-- Primeira etapa -->
    <div class="etapa primeira" v-if="state.etapaUm">
      <div class="itens">
        <div class="quadro">
          <div class="icone">
            <Svgs nome="estrela" />
          </div>
          <div class="texto">
            <h3>Criação Personalizada</h3>
            <p>Transforme sua ideia em realidade com a criação de páginas de vendas, captura ou links de BIO exatamente como você imaginou.</p>
          </div>
        </div>
        <div class="quadro">
          <div class="icone">
            <Svgs nome="cadeado" />
          </div>
          <div class="texto">
            <h3>Privacidade Garantida</h3>
            <p>Seu projeto será totalmente personalizado e sem marcas d'água ou vínculos com a Unic. Tudo será exclusivamente seu.</p>
          </div>
        </div>
        <div class="quadro">
          <div class="icone">
            <Svgs nome="chave" />
          </div>
          <div class="texto">
            <h3>Contrato Seguro</h3>
            <p>Garantimos um contrato formal para proteger você e nossa equipe, assegurando um processo de criação seguro e confiável.</p>
          </div>
        </div>
        <div class="quadro">
          <div class="icone">
            <Svgs nome="relogio" />
          </div>
          <div class="texto">
            <h3>Orçamento em 24 Horas</h3>
            <p>Cada projeto é único. Envie suas informações na próxima etapa e receba um orçamento detalhado em até 24 horas.</p>
          </div>
        </div>
      </div>
      <BotaoSalvar @click="avancarDois()" nome="Quero contratar" />
    </div>
    <!-- Segunda etapa -->
    <div class="etapa segunda" v-if="state.etapaDois">
      <label class="um">
        Qual serviço dos profissionais Unic você precisa?
        <span>*</span>
      </label>
      <div class="select">
        <Svgs nome="setinha" />
        <select v-model="state.servico">
          <option value="" selected disabled>Selecionar</option>
          <option value="Página de vendas">Página de Vendas</option>
          <option value="Página de Captura">Página de Captura</option>
          <option value="Link de BIO">Link de BIO</option>
        </select>
      </div>
      <label>
        Descreva com muitos detalhes o que precisa
        <span>*</span>
      </label>
      <textarea v-model="state.descricao" spellcheck="false" placeholder="Descreva aqui..."></textarea>
      <BotaoSalvar nome="Enviar Mensagem" @click="enviarMensagem()" />
    </div>
    <!-- Etapa -->
    <div class="etapa terceira" v-if="state.etapaTres">
      <h3>Enviaremos seu orçamento em até 24h ⏳</h3>
      <p>Em até 24 horas, você receberá um orçamento personalizado que atenderá todas as suas necessidades. O orçamento será enviado para o WhatsApp e e-mail fornecidos. Após o envio, o time Unic entrará em contato para dar continuidade aos detalhes do seu projeto.</p>
    </div>
  </Modal>
</template>
<script setup>
import { inject, reactive, onMounted } from 'vue'
import { useStorePerfil } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import Svgs from '@svgs'
import axios from 'axios'

const emitter = inject('emitter')
const storePerfil = useStorePerfil()

const state = reactive({
  etapaUm: true,
  etapaDois: false,
  etapaTres: false,
  servico: '',
  nome: '',
  whatsapp: '',
  email: '',
  instagram: '',
  descricao: '',
  links: '',
  urgencia: '',
  orcamento: ''
})

function avancarDois() {
  state.etapaUm = false
  state.etapaDois = true
}

const webhookUrl = 'https://discord.com/api/webhooks/1272277457583603808/RM-4y6emQ-cr2BJbGJj3aMd4eoVdSUZ0gmhQeQMKxVMIajYx6NNFWebUI7qQlwEMF82L'

const enviarParaDiscord = () => {
  const embed = {
    title: 'Usuário Unic interessado nos serviços Unic',
    fields: [
      {
        name: 'Serviço Desejado',
        value: state.servico,
        inline: false
      },
      {
        name: 'Nome',
        value: storePerfil.usuario?.nome,
        inline: false
      },
      {
        name: 'WhatsApp',
        value: storePerfil.usuario?.contato?.whatsapp,
        inline: false
      },
      {
        name: 'E-mail',
        value: storePerfil.usuario?.email,
        inline: false
      },
      {
        name: 'Descrição',
        value: state.descricao,
        inline: false
      }
    ],
    color: 0x0275ff
  }

  axios
    .post(webhookUrl, { embeds: [embed] })
    .then(() => {
      emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Mensagem enviada com sucesso!' })
      state.etapaDois = false
      state.etapaTres = true
    })
    .catch(() => {
      emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Erro ao enviar mensagem!' })
    })
}

function enviarMensagem() {
  if (!validarDados()) return
  enviarParaDiscord()
}

function validarDados() {
  let mensagem
  if (!state.servico || !state.descricao) mensagem = 'Preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

function limparCampos() {
  state.servico = ''
  state.nome = ''
  state.whatsapp = ''
  state.email = ''
  state.instagram = ''
  state.descricao = ''
  state.links = ''
  state.urgencia = ''
  state.orcamento = ''
  state.etapaUm = true
  state.etapaDois = false
  state.etapaTres = false
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.etapa {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
  animation: fadeIn 0.3s linear;
}

.primeira .itens {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.primeira .quadro {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  margin: 0 0 20px 0;
}

.primeira .quadro .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 20px;
  border: 2px solid var(--cor-azul);
  margin: 0 20px 0 0;
}

.primeira .quadro .icone svg {
  width: 25px;
  min-width: 25px;
  max-height: 25px;
  fill: var(--cor-branco);
}

.primeira .quadro .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.primeira .quadro .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.primeira .quadro .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.primeira .quadro .texto p span {
  font-family: var(--bold);
  color: var(--cor-azul);
}

.segunda form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.segunda label.um {
  margin: 0 0 10px 0;
}

.segunda button {
  margin: 20px 0 0 0;
}

.terceira {
  text-align: center;
  align-items: center;
  padding: 20px 0 30px 0;
}

.terceira h3 {
  font-family: var(--bold);
  font-size: var(--f5);
  color: var(--cor-branco);
  width: 100%;
  max-width: 430px;
}

.terceira p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 10px 0 20px 0;
}

.terceira h4 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-azul);
  line-height: 1.5;
  width: 100%;
  max-width: 430px;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
}
</style>
